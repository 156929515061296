<template>
  <div>
    <module-list>
      <template v-slot:contentBlock>
        <v-data-table
          dense
          fixed-header
          :search="search"
          :headers="collectionHeaders"
          :items="publicCollectionSchemas"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :hide-default-footer="$vuetify.breakpoint.mdAndUp"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.status`]="{ item }">
            <icon-display
              v-if="!isCollectionSchemaPublished(item)"
              @actionClick="publishCollectionSchema(item)"
              :icon="$icones.offLine"
              :small="false"
              toolTip="La collection n'est pas activée"
            />
            <icon-display
              v-else
              @actionClick="unpublishCollectionSchema(item)"
              :icon="$icones.onLine"
              :small="false"
              toolTip="La collection est activée"
            />
          </template>
          <!-- <template v-slot:[`item.name`]="{ item }">
            <icon-display
              v-if="item.booker && item.booker.active"
              :icon="$icones.booker"
            />
            <span class="mr-2">{{ item.name }}</span>
            <icon-display
              v-if="item.passport && item.passport.active"
              :icon="$icones.passbeaute"
            />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="getLocalShop(item.abId)">
              <icon-display
                :icon="$icones.shop"
                @actionClick="editedItem(item)"
                toolTip="cliquez ici pour éditer la configuration de la boutique"
              />
              <icon-display
                :icon="$icones.resource"
                @actionClick="linkResource(item)"
                toolTip="cliquez ici pour éditer les ressources de la boutique"
              />
              <icon-display
                :icon="$icones.geoGroupement"
                @actionClick="linkGeogroupments(item)"
                toolTip="cliquez ici pour éditer les ressources de la boutique"
              />
              <icon-display
                :icon="$icones.access"
                @actionClick="createAccess(item)"
                toolTip="cliquez ici pour éditer les accés utilisateur de la boutique"
              />
            </div>
          </template>

          <template v-slot:[`group.header`]="{ group, toggle, isOpen, items }">
            <td :colspan="abHeaders.length + 1" class="grey darken-2">
              <div class="d-inline">
                <v-btn icon @click="toggle">
                  <v-icon color="white" v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon color="white" v-if="!isOpen">mdi-chevron-down</v-icon>
                </v-btn>
                <span
                  v-if="items.length === 1"
                  style="color: white; font-weight: bold"
                  >{{ group }} - {{ items.length }} boutique</span
                >
                <span v-else style="color: white; font-weight: bold"
                  >{{ group }} - {{ items.length }} boutiques</span
                >
              </div>
              <v-divider v-if="!isOpen" dark></v-divider>
            </td>
          </template> -->
        </v-data-table>
      </template>
      <template v-slot:footerBlock>
        <div class="d-flex justify-space-between">
          <div>
            <v-btn-toggle>
              <v-btn @click="itemsPerPage = 10">10</v-btn>
              <v-btn @click="itemsPerPage = 25">25</v-btn>
              <v-btn @click="itemsPerPage = 50">50</v-btn>
              <v-btn @click="itemsPerPage = 100">100</v-btn>
              <v-btn @click="itemsPerPage = -1">Tous</v-btn>
            </v-btn-toggle>
          </div>
          <v-pagination
            v-if="pageCount > 1"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </div>
      </template>
    </module-list>
  </div>
</template>

<script>
// import layoutDatabase from "@/layouts/layout-database.vue";
import layoutHome from '@/layouts/layout-home.vue'
import ModuleList from '../../../components/general/moduleList.vue'
import IconDisplay from '../../../components/helpers/iconDisplay.vue'

export default {
  components: {
    ModuleList,
    IconDisplay,
  },
  data: () => {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      search: '',
      collectionHeaders: [
        {
          text: 'Status',
          value: 'status',
          width: 50,
          divider: true,
          sortable: true,
          filterable: true,
          align: 'center',
        },
        {
          text: 'Nom',
          value: 'name',
          width: 150,
          divider: true,
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          text: 'Actions',
          value: 'actions',
          width: 100,
          divider: true,
          sortable: false,
        },
      ],
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {
    await this.$store.dispatch('collections/fetchPublicCollectionSchemas')
    await this.$store.dispatch('collections/fetchCollectionSchemas')
  },
  computed: {
    publicCollectionSchemas() {
      return this.$store.state.collections.publicCollectionSchemas
    },
    collectionSchemas() {
      return this.$store.state.collections.collectionSchemas
    },
  },
  methods: {
    isCollectionSchemaPublished(publicCollectionSchema) {
      const collectionSchemaIndex = this.collectionSchemas.findIndex((cs) => {
        return cs.type === publicCollectionSchema.type
      })

      return collectionSchemaIndex !== -1
    },
    getCollectionSchema(publicCollectionSchema) {
      const collectionSchemaIndex = this.collectionSchemas.findIndex(
        (cs) => cs.type === publicCollectionSchema.type
      )

      return this.collectionSchemas[collectionSchemaIndex]
    },
    async publishCollectionSchema(publicCollectionSchema) {
      await this.$store.dispatch(
        'collections/publishCollectionSchema',
        publicCollectionSchema
      )
    },
    async unpublishCollectionSchema(publicCollectionSchema) {
      const collectionSchema = this.getCollectionSchema(publicCollectionSchema)

      await this.$store.dispatch(
        'collections/unpublishCollectionSchema',
        collectionSchema
      )
    },
  },
}
</script>

<style scoped></style>
