<template>
  <div>
    <v-dialog
      width="450"
      persistent
      v-model="newItemPopup"
      :key="newItemPopupKey"
    >
      <action-modal
        @actionModal="actionForm"
        :title="
          newItemModel
            ? 'Modifier un objet de collection'
            : 'Ajouter un objet de collection'
        "
      >
        <template v-slot:actionModalContent>
          <collection-item-form
            ref="itemForm"
            :schema="thisCollectionSchema"
            :item="newItemModel"
            @onClose="closePopup"
          ></collection-item-form>
        </template>
      </action-modal>
    </v-dialog>
    <module-list>
      <template v-slot:actionBlock>
        <div>
          <v-btn class="pr-4" mandatory @click="addNewCollectionItem">
            Nouveau
          </v-btn>
        </div>
      </template>
      <template v-slot:contentBlock>
        <v-data-table
          dense
          fixed-header
          height="70vh"
          :search="search"
          :headers="collectionHeaders"
          :items="collectionItems"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :hide-default-footer="$vuetify.breakpoint.mdAndUp"
          @page-count="pageCount = $event"
        >
          <template
            v-slot:[slot.name]="{ item }"
            v-for="slot in collectionSlots"
          >
            <span
              :key="slot.key"
              :style="
                fieldValue(item.attributes[slot.key]) === 'sys_complexe_value'
                  ? 'font-style: italic;'
                  : ''
              "
            >
              {{
                fieldValue(item.attributes[slot.key]) === 'sys_complexe_value'
                  ? 'Valeur complexe'
                  : fieldValue(item.attributes[slot.key])
              }}
            </span>
          </template>
          <!-- <template v-slot:[`item.status`]="{ item }">
            <icon-display
              v-if="!isCollectionSchemaPublished(item)"
              @actionClick="publishCollectionSchema(item)"
              :icon="$icones.offLine"
              :small="false"
              toolTip="La collection n'est pas activée"
            />
            <icon-display
              v-else
              @actionClick="unpublishCollectionSchema(item)"
              :icon="$icones.onLine"
              :small="false"
              toolTip="La collection est activée"
            />
          </template> -->
          <!-- <template v-slot:[`item.name`]="{ item }">
            <icon-display
              v-if="item.booker && item.booker.active"
              :icon="$icones.booker"
            />
            <span class="mr-2">{{ item.name }}</span>
            <icon-display
              v-if="item.passport && item.passport.active"
              :icon="$icones.passbeaute"
            />
          </template> -->
          <template v-slot:[`item.actions`]="{ item }">
            <icon-display
              :icon="$icones.edit"
              @actionClick="editCollectionItem(item)"
              toolTip="Modifier"
            />
            <icon-display
              :icon="$icones.delete"
              @actionClick="deleteCollectionItem(item)"
              toolTip="Supprimer"
            />
          </template>

          <!-- <template v-slot:[`group.header`]="{ group, toggle, isOpen, items }">
            <td :colspan="abHeaders.length + 1" class="grey darken-2">
              <div class="d-inline">
                <v-btn icon @click="toggle">
                  <v-icon color="white" v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon color="white" v-if="!isOpen">mdi-chevron-down</v-icon>
                </v-btn>
                <span
                  v-if="items.length === 1"
                  style="color: white; font-weight: bold"
                  >{{ group }} - {{ items.length }} boutique</span
                >
                <span v-else style="color: white; font-weight: bold"
                  >{{ group }} - {{ items.length }} boutiques</span
                >
              </div>
              <v-divider v-if="!isOpen" dark></v-divider>
            </td>
          </template> -->
        </v-data-table>
      </template>
      <template v-slot:footerBlock>
        <div class="d-flex justify-space-between">
          <div>
            <v-btn-toggle>
              <v-btn @click="itemsPerPage = 10">10</v-btn>
              <v-btn @click="itemsPerPage = 25">25</v-btn>
              <v-btn @click="itemsPerPage = 50">50</v-btn>
              <v-btn @click="itemsPerPage = 100">100</v-btn>
              <v-btn @click="itemsPerPage = -1">Tous</v-btn>
            </v-btn-toggle>
          </div>
          <v-pagination
            v-if="pageCount > 1"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </div>
      </template>
    </module-list>
  </div>
</template>

<script>
import swal from 'sweetalert'
// import layoutDatabase from "@/layouts/layout-database.vue";
import layoutHome from '@/layouts/layout-home.vue'
import ModuleList from '../../../../components/general/moduleList.vue'
import IconDisplay from '../../../../components/helpers/iconDisplay.vue'
import CollectionItemForm from '@/components/database/collections/collectionItemForm.vue'
import ActionModal from '@/components/helpers/actionModal.vue'

export default {
  components: {
    ModuleList,
    IconDisplay,
    CollectionItemForm,
    ActionModal
  },
  data: () => {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      search: '',
      newItemPopup: false,
      newItemPopupKey: null,
      newItemModel: null,
      headerTypes: [
        'string',
        'textarea',
        'multilangue_string',
        'multilangue_textarea',
        'integer',
        'float'
      ]
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {
    await this.$store.dispatch('collections/fetchCollectionSchemas')
    await this.$store.dispatch(
      'collections/fetchCollectionItems',
      this.$route.params.type
    )
  },
  computed: {
    collectionHeaders() {
      return [
        ...this.thisCollectionSchema.fields
          .filter(f => this.headerTypes.includes(f.type))
          .map(f => {
            return {
              text: f.name,
              value: 'display_' + f.slug,
              width: 100,
              divider: true,
              sortable: false
            }
          }),
        {
          text: 'Actions',
          value: 'actions',
          width: 100,
          divider: true,
          sortable: false
        }
      ]
    },
    collectionSlots() {
      return [
        ...this.thisCollectionSchema.fields.map(f => {
          return {
            name: 'item.display_' + f.slug,
            key: f.slug
          }
        })
      ]
    },
    collectionItems() {
      return this.$store.state.collections.collectionItems[
        this.$route.params.type
      ]
    },
    collectionSchemas() {
      return this.$store.state.collections.collectionSchemas
    },
    thisCollectionSchema() {
      if (!this.collectionSchemas || !this.collectionSchemas.length) {
        return null
      }

      return this.collectionSchemas.find(
        cs => cs.type === this.$route.params.type
      )
    }
  },
  methods: {
    fieldValue(fieldValue) {
      if (typeof fieldValue === 'object' && fieldValue !== null) {
        if (Object.keys(fieldValue).includes('fr')) {
          return fieldValue.fr
        }

        return 'sys_complexe_value'
      }

      return fieldValue
    },
    addNewCollectionItem() {
      this.newItemPopup = true
      this.newItemPopupKey = Date.now()
      this.newItemModel = null
    },
    editCollectionItem(item) {
      this.newItemPopup = true
      this.newItemPopupKey = Date.now()
      this.newItemModel = JSON.parse(JSON.stringify(item))
    },
    async deleteCollectionItem(item) {
      const result = await swal({
        title: 'Etes-vous sûr ?',
        text: 'Cette action est irréversible.',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })

      if (result) {
        await this.$store.dispatch('collections/deleteCollectionItem', {
          type: this.thisCollectionSchema.type,
          id: item.id
        })
      }
    },
    closePopup() {
      this.newItemPopup = false
      this.newItemModel = null
    },
    actionForm(e) {
      if (e) {
        this.handleForm()
      } else {
        this.exitForm()
      }
    },
    handleForm() {
      this.$refs.itemForm.handleForm()
    },
    exitForm() {
      this.closePopup()
    }
  }
}
</script>

<style scoped></style>
