<template>
  <div style="height: 70vh; overflow-y: auto; overflow-x: none;">
    <div v-if="!schema">
      Une erreur est survenue.
    </div>
    <div v-else>
      <item-form-fields :fields="schema.fields" :form-data="formData" />
    </div>
  </div>
</template>

<script>
import ItemFormFields from '@/components/database/collections/form/itemFormFields.vue'

export default {
  components: {
    ItemFormFields
  },
  props: {
    schema: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formData: this.item ? this.item.attributes : {}
    }
  },
  methods: {
    async handleForm() {
      if (!this.schema) {
        return
      }

      if (this.item) {
        await this.$store.dispatch('collections/updateCollectionItem', {
          id: this.item.id,
          type: this.schema.type,
          data: {
            attributes: this.formData
          }
        })
      } else {
        await this.$store.dispatch('collections/createCollectionItem', {
          type: this.schema.type,
          data: {
            schemaId: this.schema.id,
            attributes: this.formData
          }
        })
      }

      this.$emit('onClose')
    }
  }
}
</script>

<style></style>
