<template>
  <div>
    <div v-for="field in fields" :key="field.id">
      <!-- STRING -->
      <div v-if="field.type === 'string'">
        <v-text-field
          v-model="formData[field.slug]"
          :placeholder="field.name"
        />
      </div>
      <!-- TEXTAREA -->
      <div v-if="field.type === 'textarea'">
        <v-textarea v-model="formData[field.slug]" :placeholder="field.name" />
      </div>
      <!-- MULTILANG STRING -->
      <div v-if="field.type === 'multilangue_string'">
        <multilang-text-field
          v-model="formData[field.slug]"
          :placeholder="field.name"
        />
      </div>
      <!-- MULTILANG TEXTAREA -->
      <div v-if="field.type === 'multilangue_textarea'">
        <multilang-textarea
          v-model="formData[field.slug]"
          :placeholder="field.name"
        />
      </div>
      <!-- INTEGER -->
      <div v-if="field.type === 'integer'">
        <v-text-field
          type="number"
          step="1"
          v-model="formData[field.slug]"
          :placeholder="field.name"
        />
      </div>
      <!-- FLOAT -->
      <div v-if="field.type === 'float'">
        <v-text-field
          type="number"
          step="any"
          v-model="formData[field.slug]"
          :placeholder="field.name"
        />
      </div>
      <!-- LIST -->
      <div v-if="field.type === 'list'">
        <div>
          <strong>{{ field.name }}</strong>
        </div>
        <div>
          <div
            class="d-flex align-center"
            v-for="item in formData[field.slug]"
            :key="item.id"
          >
            <v-text-field
              type="text"
              v-model="item.value"
              :placeholder="field.name"
            />
            <v-btn @click="deleteItemList(field.slug, item)" text>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="text-center mb-3">
          <v-btn @click="addItemToList(field.slug)" text>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import MultilangTextarea from '@/components/helpers/multilangTextarea.vue'
import MultilangTextField from '@/components/helpers/multilangTextField.vue'

export default {
  components: {
    MultilangTextarea,
    MultilangTextField
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.fields.forEach(element => {
      Vue.set(
        this.formData,
        element.slug,
        this.formData[element.slug] ? this.formData[element.slug] : null
      )
    })
  },
  methods: {
    addItemToList(slug) {
      if (!this.formData[slug]) {
        this.formData[slug] = []
      }

      this.formData[slug].push({
        id: Date.now().toString(32) + Math.random().toString(32),
        value: null
      })
    },
    deleteItemList(slug, item) {
      const itemIndex = this.formData[slug].findIndex(i => i.id === item.id)
      this.formData[slug].splice(itemIndex, 1)
    }
  }
}
</script>

<style></style>
